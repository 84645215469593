<template>
  <div v-if="currentSelectOption" class="px-2">
    <AutomationSetupOptionButtonTrigger v-if="currentSelectOption.triggerType === AutomationTriggerType.BUTTON" :parent-id="parentId" :source="source" />
    <template v-else>
      <p class="text-sm font-semibold mb-4 capitalize">{{ currentSelectOption.optionType.toLowerCase() }}</p>
      <div class="space-y-3 p-3 border rounded-md">
        <div class="flex gap-2 items-center">
          <div class="bg-gray-50 border rounded-md w-8 h-8 flex items-center justify-center">
            <Icon :name="currentSelectOption.icon" size="16" />
          </div>
          <p class="text-xs text-gray-900 max-w-52">{{ currentSelectOption.label }}</p>
          <UButton
            color="gray" variant="ghost"
            class="ml-auto"
            icon="i-heroicons:trash"
            size="xs"
            @click="onRemove"
          />
        </div>
        <template v-if="hasOptionSetting">
          <UDivider />
          <component
            :is="OptionComponent[currentSelectOption.type as keyof typeof OptionComponent]"
            :option="currentSelectOption"
            :parent-id="parentId"
            :source="source"
            @change="onChange"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { cloneDeep } from 'lodash-es'
import type { AutomationOption, AutomationOptionData } from '#automation/types'
import { ActionType, AutomationOptionType, AutomationTriggerType, ConditionType, TriggerType } from '#automation/constants'

defineProps({
  parentId: {
    type: String,
    required: true
  },
  source: {
    type: String,
    required: true
  }
})
const { currentSelectOption, dataError, currentActionIndex, automationError } = useAutomationSharedState()

const hasOptionSetting = computed(() => {
  return Object.keys(OptionComponent).includes(currentSelectOption.value.type)
})

const onRemove = () => {
  currentSelectOption.value = ({ optionType: currentSelectOption.value.optionType } as AutomationOption)
}

const onChange = (option: AutomationOptionData) => {
  switch (currentSelectOption.value.optionType) {
    case AutomationOptionType.ACTION:
      automationError.value.actions[currentActionIndex.value] = ''
      dataError.value.actions[currentActionIndex.value] = ''
      break

    case AutomationOptionType.CONDITION:
      automationError.value.condition = ''
      dataError.value.condition = ''
      break

    case AutomationOptionType.TRIGGER:
      automationError.value.trigger = ''
      dataError.value.trigger = ''
      break
  }

  const updateOption = cloneDeep(currentSelectOption.value)
  Object.assign(updateOption, option)
  currentSelectOption.value = updateOption
}

const OptionComponent = {
  [TriggerType.TASK_MOVED_TO_SECTION]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/trigger/MovedToSection.vue')
  ),
  [TriggerType.DUE_DATE_ARRIVES]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/trigger/DueDateOnTime.vue')
  ),
  [TriggerType.DUE_DATE_OVERDUE]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/trigger/DueDateOnTime.vue')
  ),
  [TriggerType.DUE_DATE_CHANGED]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/DueDate.vue')
  ),
  [TriggerType.ASSIGNEE_ADDED]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/Assignee.vue')
  ),
  [TriggerType.STATUS_CHANGED]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/trigger/StatusChanged.vue')
  ),
  [TriggerType.CUSTOM_FIELD_CHANGED]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/custom-field/Index.vue')
  ),
  [ConditionType.TASK_IN_SECTION]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/Section.vue')
  ),
  [ConditionType.DUE_DATE_IS]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/DueDate.vue')
  ),
  [ConditionType.TASK_CREATED_BY]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/condition/TaskCreatedBy.vue')
  ),
  [ConditionType.ASSIGNEE_IS]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/Assignee.vue')
  ),
  [ConditionType.CUSTOM_FIELD_IS]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/custom-field/Index.vue')
  ),
  [ConditionType.LABEL_IS]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/condition/LabelIs.vue')
  ),
  [ConditionType.TASKTYPE_IS]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/condition/TaskTypeIs.vue')
  ),
  [ActionType.CHANGE_ASSIGNEE]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/Assignee.vue')
  ),
  [ActionType.CHANGE_DUE_DATE]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/action/ChangeDueDate.vue')
  ),
  [ActionType.CHANGE_STATUS]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/action/ChangeStatus.vue')
  ),
  [ActionType.CREATE_SUBTASK]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/action/CreateSubTask.vue')
  ),
  [ActionType.MOVE_TO_BOARD]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/action/MoveToBoard.vue')
  ),
  [ActionType.MOVE_TO_SECTION]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/Section.vue')
  ),
  [ActionType.SET_CUSTOM_FIELD]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/custom-field/Index.vue')
  ),
  [ActionType.SLACK_SEND_MESSAGE]: defineAsyncComponent(
    () => import('#automation/components/automation-setup/option/action/SlackSendMessage.vue')
  ),
}
</script>
