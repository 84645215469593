<template>
  <template v-if="!currentSelectOption.label">
    <div class="px-2 space-y-1 mb-4">
      <p class="text-sm font-semibold">{{ sideBarHeader }}</p>
      <p class="text-xs text-gray-500">{{ sideBarDescription }}</p>
    </div>
    <div v-for="(category, categoryIndex) in renderOptions" :key="categoryIndex" class="flex flex-col gap-2">
      <p class="px-2 text-xs font-semibold text-gray-500">{{ category.label }}</p>
      <UButton
        v-for="(option, index) in category.options"
        :key="index"
        :data-test="`automation-option-${option.type}`"
        truncate
        :ui="{
          padding: {
            md: '!p-2'
          },
          font: 'font-normal',
        }"
        color="gray" variant="ghost"
        class="flex gap-2 items-center"
        @click="onSelectOption(option)"
      >
        <div class="bg-gray-50 border rounded-md w-8 h-8 flex items-center justify-center">
          <Icon :name="option.icon" size="16" />
        </div>
        <p class="text-sm max-w-52 truncate">{{ option.label }}</p>
      </UButton>
      <UDivider v-if="categoryIndex !== renderOptions.length - 1" class="px-2" />
    </div>
  </template>
  <AutomationSetupOption v-else :parent-id="parentId" :source="source" />
</template>

<script lang="ts" setup>
import { ActionOptions, TriggerOptions, AutomationOptionType, ConditionOptions } from '#automation/constants'
import type { AutomationOption } from '#automation/types'

defineProps<{
  parentId: string
  source: MorphSource
}>()

const { currentSelectOption, automationError, currentActionIndex } = useAutomationSharedState()

const renderOptions = computed(() => {
  if (currentSelectOption.value.optionType === AutomationOptionType.TRIGGER) {
    return TriggerOptions
  } else if (currentSelectOption.value.optionType === AutomationOptionType.ACTION) {
    return ActionOptions
  }

  return ConditionOptions
})

const sideBarHeader = computed(() => {
  if (currentSelectOption.value.optionType === AutomationOptionType.TRIGGER) {
    return 'Trigger'
  } else if (currentSelectOption.value.optionType === AutomationOptionType.ACTION) {
    return 'Actions'
  }

  return 'Condition'
})

const sideBarDescription = computed(() => {
  if (currentSelectOption.value.optionType === AutomationOptionType.TRIGGER) {
    return 'Add a trigger that sets the rule in motion.'
  } else if (currentSelectOption.value.optionType === AutomationOptionType.ACTION) {
    return 'Add an action that occurs as a result of the rule.'
  }

  return 'Add a condition that must be met for the rule to run.'
})

const onSelectOption = (option: AutomationOption) => {
  currentSelectOption.value = option
  switch (option.optionType) {
    case AutomationOptionType.ACTION:
      automationError.value.actions[currentActionIndex.value] = ''
      break
    case AutomationOptionType.CONDITION:
      automationError.value.condition = ''
      break
    case AutomationOptionType.TRIGGER:
      automationError.value.trigger = ''
      break
  }
}
</script>
