<template>
  <div class="relative w-full group flex justify-center" data-test="automation-action">
    <AutomationSetupPanelOption v-if="actions?.length === 1" :option="actions[0]" />
    <div v-else class="border rounded-md p-4 pt-2 flex flex-col gap-2 w-full bg-white">
      <div class="flex gap-2 justify-between items-center">
        <p class="text-gray-500 font-medium text-xs">Then</p>
        <UTooltip
          :class="['invisible group-hover:visible ml-auto']"
          text="Remove all actions"
          :popper="{
            placement: 'top',
            arrow: true,
          }"
        >
          <UButton
            color="gray" variant="ghost"
            icon="i-heroicons:trash"
            size="xs"
            @click.prevent.stop="removeAction(0, true)"
          />
        </UTooltip>
      </div>
      <div class="flex flex-col">
        <div v-for="(action, index) in actions" :key="index">
          <div v-if="index !== 0" class="w-fit ml-4">
            <UDivider
              :ui="{
                container: {
                  vertical: 'my-0'
                },
                border: {
                  base: 'border-gray-400'
                }
              }" size="xs" type="dashed" orientation="vertical"
              class="h-10"
            >
              <UBadge label="And" color="gray" variant="soft" size="xs" />
            </UDivider>
          </div>
          <AutomationSetupPanelOption
            v-if="action.label"
            :show-header="false" :option="action"
            :action-index="index"
          />
          <AutomationSetupPanelAddOption v-else :type="AutomationOptionType.ACTION" :action-index="index" />
        </div>
      </div>
    </div>
    <div
      class="cursor-pointer text-white absolute w-4 h-4 bg-black rounded-full flex items-center justify-center -bottom-2 z-10 opacity-0 group-hover:opacity-100 duration-100"
      data-test="add-another-action"
      @click="onAddAction"
    >
      <UTooltip
        text="Add another action"
        :popper="{
          placement: 'right',
          arrow: true,
        }"
      >
        <Icon name="heroicons:plus-20-solid" size="12" />
      </UTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AutomationOptionType } from '#automation/constants'
import type { AutomationOption } from '#automation/types'

const { currentRule, removeAction, currentActionIndex, currentSelectOption } = useAutomationSharedState()
const actions = computed(() => currentRule.value.actions)

const onAddAction = () => {
  currentRule.value.actions.push({ optionType: AutomationOptionType.ACTION } as AutomationOption)
  currentActionIndex.value = actions.value.length - 1
  currentSelectOption.value = currentRule.value.actions[currentActionIndex.value]
}
</script>
